body {
  margin: 0;
  font-family: "Zilla Slab", sans-serif !important;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiFormControlLabel-label {
  font-size: 0.8rem !important;
}

.MuiButton-contained {
  box-shadow: unset !important;
}

.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-formControl {
  background-color: white;
}

.MuiButton-containedSecondary .MuiButton-label {
  color: white;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
